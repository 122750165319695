/* eslint-disable */
import { ref, onMounted, getCurrentInstance, reactive, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { receiveItemUseCase, reportUseCase } from '@/domain/usecase'
import ReceiveItemForm from '@/views/transactions/receive-item/detail/ReceiveItemForm.vue'
import HjexTimeline from '@/views/shared-components/timeline/HjexTimeline.vue'
import moment from 'moment'
import { fileWrite } from '@/plugins/system/filesystem'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { environment } from '@/utils'
import Lightgallery from '@/views/shared-components/lightgallery/index.vue'
import {
  TDeliveryStatusLogDelivery,
  TLabelUsedDelivery,
  TRecipientDelivery,
  TTransPengirimanHdDetail
} from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  components: {
    ReceiveItemForm,
    Lightgallery,
    HjexTimeline
  },
  setup() {
    const cannotPrintStatus = ref([3, 7, 8, 9])
    const printAgentOnly = ref(false)
    const app = getCurrentInstance()
    // eslint-disable-next-line  object-curly-newline
    const { $toast, $strInd, $confirm, $swal } = app!.appContext.config.globalProperties
    const data = ref<TTransPengirimanHdDetail>()
    const dialogForm = ref(false)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const currentTab = ref(0)
    const isFromGuid = ref(false)
    const showGuid = ref(false)
    const agenAsalId = ref('')
    const dataAnsuransi = ref({
      hasAnsuransi: false,
      nama: '-',
      noAsuransi: ''
    })
    const accessGuid = ref(checkPermission(PermissionEnum.ShowGuid))
    const canPrint = ref(checkPermission(PermissionEnum.CanPrint))
    const deliveryStatusLogs = ref<Array<TDeliveryStatusLogDelivery>>([])
    const usedLabels = ref<Array<TLabelUsedDelivery>>([])
    const deliveryRecipient = ref<TRecipientDelivery>()

    const backRouter = () => {
      $toast.add({
        severity: 'error', detail: 'Detail penerimaan tidak ditemukan', group: 'bc', life: 1500
      })
      store.dispatch('hideLoading')
      router.go(-1)
    }

    const checkPrintAgentOnly = () => {
      // if (store.state?.appActiveUser?.group?.GroupUserId === 11) {
      if (store.getters['appActiveUser/getAgent']?.id !== agenAsalId.value) {
        printAgentOnly.value = true
      }
      // }
    }

    // const generateImage = () => {
    //   if (data.value.TransPenerimaan) {
    //     data.value.TransPenerimaan.TransPenerimaanFiles = data.value.TransPenerimaan.TransPenerimaanFiles.map((item: any, idx: number) => {
    //       item.file = environment.getApiBase() + item.UrlFile
    //       item.src = environment.getApiBase() + item.UrlFile
    //       item.alt = item.FileName ? item.FileName : `Bukti-${idx + 1}`
    //       return item
    //     })
    //   }
    //   data.value.TransPenerimaanFiles = data.value.TransPenerimaanFiles.map((item: any, idx: number) => {
    //     item.file = environment.getApiBase() + item.UrlFile
    //     item.src = environment.getApiBase() + item.UrlFile
    //     item.alt = item.FileName ? item.FileName : `Bukti-${idx + 1}`
    //     return item
    //   })
    // }
    const generateDataAsuransi = () => {
      if (data.value?.TransPengirimanDetails && data.value?.TransPengirimanDetails.length > 0) {
        if (data.value.TransPengirimanDetails[0].AsuransiId) {
          dataAnsuransi.value.hasAnsuransi = true
          dataAnsuransi.value.nama = data.value.TransPengirimanDetails[0].AsuransiNama
        }
      }
    }

    const getDetailLogDelivery = async (id: string) => {
      const {
        result,
        error
      } = await receiveItemUseCase.getDeliveryStatusLogs(id)
      if (!error) {
        deliveryStatusLogs.value = result
      }
    }

    const getDetailLabelUsed = async (id: string) => {
      const {
        result,
        error
      } = await receiveItemUseCase.getLabelUsed(id)
      if (!error) {
        usedLabels.value = result
      }
    }

    const getDetailRecipient = async (id: string) => {
      const {
        result,
        error
      } = await receiveItemUseCase.getRecipientDelivery(id)
      if (!error) {
        deliveryRecipient.value = {
          ...result,
          Images: result.Images.map((img, idx) => ({
            ...img,
            src: `${environment.getApiBase()}${img.ThumbnailUrl}`,
            imgSrc: `${environment.getApiBase()}${img.ImageUrl === null || img.ImageUrl === '' ? img.ThumbnailUrl.replace('_thumb', '') : img.ImageUrl}`,
            alt: `bukti-${idx}`
          }))
        }
      }
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      const id = typeof route.params.id === 'string' ? route.params.id : ''
      isFromGuid.value = id.length === 36
      const {
        message,
        result,
        error
      } = await receiveItemUseCase.getDetail(id)
      if (!error) {
        data.value = result
        Promise.all([
          getDetailLogDelivery(result?.Id.toString()),
          getDetailLabelUsed(result?.Id.toString()),
          getDetailRecipient(result?.Id.toString())
        ])
        generateDataAsuransi()
      } else {
        $toast.add({
          severity: 'error', detail: message, group: 'bc', life: 3000
        })
        backRouter()
      }
      store.dispatch('hideLoading')
    }

    const proccessDropAgent = async () => {
      store.dispatch('showLoading')
      const response = await receiveItemUseCase.submitDropAgent([{ PengirimanHdId: data.value?.Id }])
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        getAllData()
      }
    }

    const showingGuid = async () => {
      store.dispatch('showLoading')
      const response = await receiveItemUseCase.submitLogReqUid(data.value?.Id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal menampilkan guid', group: 'bc', life: 3000
        })
      } else {
        showGuid.value = true
      }
      store.dispatch('hideLoading')
    }

    const submitDropAgent = () => {
      $confirm.require({
        header: 'Diterima Agen',
        message: 'Apakah anda sudah mengecek kembali pilihan resi anda? Jika sudah diproses data tidak dapat dibatalkan',
        icon: 'pi pi-info-circle',
        accept: async () => {
          proccessDropAgent()
        }
      })
    }

    const showForm = () => {
      dialogForm.value = true
    }

    const hideForm = () => {
      dialogForm.value = false
    }

    const detectBackgroundLabel = (label: any) => {
      if (label) {
        if (label.Warna) {
          return {
            background: label.Warna
          }
        }
      }
      return {}
    }

    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const printResi = () => {
      $swal.fire({
        title: 'Pilih opsi ?',
        // text: 'You won be able to revert this!',
        // icon: 'question',
        imageUrl: require('@/assets/img/icon/printer.svg'),
        imageWidth: 400,
        imageHeight: 150,
        imageAlt: 'Custom image',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#ff9800',
        cancelButtonColor: '#F44336',
        denyButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Download Resi',
        cancelButtonText: 'Kembali',
        denyButtonText: 'Tampilkan Resi'
      }).then(async (result: any) => {
        store.dispatch('showLoading')
        if (await result.isConfirmed) {
          // eslint-disable-next-line no-shadow
          const result = await reportUseCase.getPdfResi(Number(route.params.id), store.getters['appActiveUser/getAppActiveUser'].id)
          // response.result.Resi
          const name = 'Resi.pdf'
          let notif = null
          //cek device
          if (await isMobile()) {
            //if mobile
            const file = await toBase64(new Blob([result.result]))
            await fileWrite(name, file)
            notif = 'Resi berhasil diunduh ke folder : DOCUMENTS'
            $toast.add({
              severity: 'success', detail: notif, group: 'bc', life: 5000
            })
          } else {
            // if web
            const fileURL = window.URL.createObjectURL(new Blob([result.result]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
          }
        }
        store.dispatch('hideLoading')
        if (result.isDenied) {
          const encryptData = encryptDecriptMethods.encrypt(data.value?.Id)
          const encryptDataUser = encryptDecriptMethods.encrypt(store.getters['appActiveUser/getAppActiveUser'].id)
          const routeData = router.resolve({ name: 'report', params: { Id: encryptData, jenisReport: 'resi', idUser: encryptDataUser } })
          window.open(routeData.href, '_blank')
        }
      })
    }

    const successCopy = () => {
      $toast.add({
        severity: 'success', detail: 'Berhasil dicopy', group: 'bc', life: 3000
      })
    }

    const errorCopy = () => {
      $toast.add({
        severity: 'error', detail: 'Gagal copy, mohon copy manual', group: 'bc', life: 3000
      })
    }

    onMounted(() => {
      getAllData()
      checkPrintAgentOnly()
    })
    return {
      route,
      data,
      toAgentId,
      dialogForm,
      isFromGuid,
      showGuid,
      accessGuid,
      moment,
      successCopy,
      errorCopy,
      showingGuid,
      getAllData,
      submitDropAgent,
      showForm,
      hideForm,
      detectBackgroundLabel,
      printResi,
      dataAnsuransi,
      canPrint,
      currentTab,
      cannotPrintStatus,
      printAgentOnly,
      deliveryStatusLogs,
      usedLabels,
      deliveryRecipient
    }
  }
})
